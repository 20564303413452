import React from 'react'
import Layout from '../components/Layout'

const notFound = () => {
  return (
    <div>
      Page not found
    </div>
  )
}

notFound.Layout = Layout
export default notFound
